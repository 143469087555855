import Vue from 'vue'
import ElementLocale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import { host } from 'env'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'zh_CN',
    fallbackLocale: 'zh_CN',
    messages: {}
})

/**
 * 配置语言环境
 * @param {*} lang
 * @param {*} messages
 * @returns
 */
function setI18nLanguage(lang, message) {
    if (message) {
        i18n.setLocaleMessage(lang, message)
    }
    i18n.locale = lang
    ElementLocale.i18n((key, value) => i18n.t(key, value))
    axios.defaults.headers.common['Accept-Language'] = lang.replace('_', '-')
    return i18n
}

/**
 * 获取语言配置项
 * @returns
 */
function getLanguageEnv() {
    const lang = localStorage.getItem('lang')
    if (!lang) {
        return import('@/api/userApi').then(res => {
            const getAppConfigStatus = res.getAppConfigStatus
            return getAppConfigStatus().then(({ map, code }) => {
                if (+code === 200) {
                    if (map?.defaultLang) {
                        const lang = map.defaultLang
                        localStorage.setItem('lang', lang)
                        location.reload()
                    }
                } else {
                    const lang = 'zh_CN'
                    return lang
                }
            })
        })
    }
    return Promise.resolve(lang)
}

/**
 * 动态加载语言包
 */
function loadLanguageAsync(needPrefix = false) {
    return getLanguageEnv().then(res => {
        const lang = res
        const prefix = needPrefix ? host.languageHost.replace('i18n/', 'wap/i18n/') : host.languageHost
        const url = `${prefix + lang}/frame/js/lang.json?v=${CDN_HASH}`
        const elUrl = `${prefix + lang}/frame/js/el.json?v=${CDN_HASH}`
        if (i18n.getLocaleMessage(lang).common) {
            return Promise.resolve(setI18nLanguage(lang))
        }
        return Promise.all([
            axios.get(url, { ignoreInterceptor: true }),
            axios.get(elUrl, { ignoreInterceptor: true })
        ]).then(res => {
            const message = Object.assign({}, res[0].data, res[1].data)
            return setI18nLanguage(lang, message)
        })
    })
}
export default i18n
export { setI18nLanguage, loadLanguageAsync, getLanguageEnv }
