import Vue from 'vue'
function install(Vue) {
    Vue.notifier = Vue.prototype.notifier = {
        success,
        fail,
        info,
        warn
    };
}

function open(type, msg) {
    Vue.prototype.$message({
        message: msg,
        type: type
    });
}

function success(msg) {
    open('success', msg);
}

function fail(msg) {
    open('error', msg);
}

function info(msg) {
    open('info', msg);
}

function warn(msg) {
    open('warning', msg);
}

Vue.use(install);

export default install;

export {
  success,
  fail,
  info,
  warn,
}
