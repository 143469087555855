const env = require('env')
const { host, config } = env

Object.keys(host).forEach(ele => {
    if (env.name === 'local') return
    if (host[ele] && typeof host[ele] === 'string' && host[ele].startsWith('//')) {
        host[ele] = window.location.protocol + host[ele]
    }
})

export { host, config }
export default env
