module.exports = {
    changeHost(domain) {
        // 表单作品内嵌数据分析页面， 必须走域名转换，不然拿不到sessionId 会跳转到frame首页,
        // this.$route.query.source === 'fl_editor'
        const s = window.location.search
        const isFormEditorAnalysis = s && s.includes('source=fl_editor')
        if (window.top !== window && !isFormEditorAnalysis) {
            return
        }
        domain = domain.toLowerCase()
        const url = window.location.href
        const urlHostname = window.location.hostname
        if (this.name === 'local') return
        if (urlHostname !== domain) {
            window.location.href = url.replace(urlHostname, domain)
        }
        if (this.isReplaced()) {
            return
        }
        const hostKeys = Object.keys(this.host)
        for (const key of hostKeys) {
            if (key === 'grantHost') {
                continue
            }
            this.host[key] = this.host[key].replace(this.hostname.split(':')[0], domain)
        }
    },
    isReplaced() {
        if (this.host.globalHOST.includes(this.hostname)) {
            return false
        }
        return true
    },
    initHost() {
        const urlDomin = window.location.hostname
        this.changeHost(urlDomin)
    }
}
