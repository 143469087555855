import i18n from '@/plugins/i18n'
// 场景类型
const BIZ_TYPE = {
    101: i18n.t('common.question'),
    102: i18n.t('common.vote'),
    103: i18n.t('common.signUp'),
    104: i18n.t('common.evaluation')
}
// 分组信息
const GROUP_INFO = { unGroup: [0, undefined], allGroup: '' }

// 投票排行榜
export const VOTE_RANK = 10

// 场景状态
export const SCENE_STATUS = {
    NOT_PUBLISHED: 1,
    PUBLISHED: 2,
    PAUSED: 3,
    STOP: 4,
    END: 5,
    MODIFIED: -2
}

export const TYPE_NAME_MAP = {
    hd: i18n.t('common.hd'),
    h5: 'H5',
    design: i18n.t('common.design'),
    lc: i18n.t('common.lc'),
    form: i18n.t('common.form')
}

export { BIZ_TYPE, GROUP_INFO }

// 报名签到详情
export const SIGN_UP_DETAIL = 'sign-up-detail'

// creationType
export const SCENE_TYPE = {
    CORP: 'corp',
    PERSON: 'person'
}
